import { Link, useHref } from "react-router-dom";

export const PlatformData = [
  {
    id: "1",
    title: "Platforms",
    tabs: [
      {
        title: "All",
        subtitle:
          "Explore the Meridian Suite of products, now available across 5 networks.",
      },
      {
        title: "Lending",
        subtitle:
          "Decentralised Money Market.",
      },
      {
        title: "DEXes",
        subtitle:
          "AMM oriented Liquidity Protocol.",
      },
      {
        title: "Stablecoins",
        subtitle:
          "Interest Free Borrowing Protocol.",
      },
      {
        title: "Trading",
        subtitle:
          "Perpetual Trading Platform.",
      },
    ],
    potentials: [
      {
        category: "Stablecoins",
        img: "/img/potentials/1.svg",
        title: "Meridian Mint",
        Link: "https://mint.meridianfinance.net",
        subtitle: `Borrowing protocol providing interest-free loans against whitelisted collateral. Loans are paid in USDM - a USD-pegged stablecoin.`,
        button: "Launch Meridian Mint",
        networks: [
          "/img/networks-icons/base.png",
          "/img/networks-icons/fuse.svg",
          "/img/networks-icons/telos.png",
        ],
      },
      {
        category: "Lending",
        img: "/img/potentials/2.svg",
        title: "Meridian Lend",
        subtitle: `Decentralized money market - Lenders earn passive income by providing liquidity, while borrowers secure overcollateralized loans.`,
        button: "Launch Meridian Lend",
        Link: "https://lend.meridianfinance.net/markets",
        networks: [
          "/img/networks-icons/meter.png",
          "/img/networks-icons/taiko.svg",
          "/img/networks-icons/fuse.svg",
          "/img/networks-icons/telos.png",
        ],
      },
      {
        category: "Trading",
        img: "/img/potentials/3.svg",
        title: "Meridian Trade",
        Link: "https://trade.meridianfinance.net",
        subtitle: `Perpetual trading platform offering up to 50x leverage, where users can trade, provide liquidity, and earn fees from trading activity.`,
        button: "Launch Meridian Trade",
        networks: [
          "/img/networks-icons/base.png",
          "/img/networks-icons/meter.png",
        ],
      },
      {
        category: "DEXes",
        img: "/img/potentials/4.png",
        title: "Meridian Swap",
        Link: "https://swaps.meridianfinance.net",
        subtitle: `AMM oriented decentralised liquidity protocol enabling users to create trading pairs, add liquidity, and earn fees from swaps.`,
        button: "Launch Meridian Swap",
        networks: ["/img/networks-icons/telos.png"],
      },
    ],
  },
];
